<template lang="pug">
  .account-info-modal
    TopBar(:user-name="currentAccount.full_name")
    .account-info-items
      AccountInfoItem(
        v-for="(attr, index) in accountInfoItemAttributes"
        :key="index"
        :name="$t(`accounts.table.columns.${attr}`)"
      )
        template(v-slot:values)
          .value.d-flex
            span.truncated
              | {{ accountAttributeValue(attr) }}
      AccountInfoItem(:name="isOrganizationsAdminList ? $t('accounts.table.columns.organizations') : $t('accounts.table.columns.shop')")
        template(v-slot:values)
          .value.d-flex(
            v-for="(name, index) in list"
            :key="index"
          )
            | {{ name }}
</template>

<script>
  // misc
  import { filter, map, get } from "lodash-es"

  import withOrganizationAdminList from "@/mixins/withOrganizationAdminList"
  import withOtaAdminList from "@/mixins/withOtaAdminList"

  const defaultAccount = {
    full_name: "",
    account_id: "",
    role: {
      name: ""
    },
    email: "",
    phone_number: "",
    current_sign_in_at: "",
    shops: [],
    organizations: []
  }

  const excludedKeys = ["full_name", "shops", "organizations"]

  export default {
    components: {
      TopBar: () => import("./TopBar"),
      AccountInfoItem: () => import("./AccountInfoItem"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    props: {
      currentAccount: {
        type: Object,
        required: true
      }
    },

    mixins: [withOtaAdminList, withOrganizationAdminList],

    computed: {
      accountInfoItemAttributes() {
        return filter(Object.keys(defaultAccount), key => !excludedKeys.includes(key))
      },

      shopNames() {
        return map(this.currentAccount.shops, "name")
      },

      organizationsNames() {
        return map(this.currentAccount.organizations, "name")
      },

      list({ isOrganizationsAdminList, organizationsNames, shopNames }) {
        return isOrganizationsAdminList ? organizationsNames : shopNames
      }
    },

    methods: {
      accountAttributeValue(attr) {
        return attr !== "role" ? this.currentAccount[attr] : get(this.currentAccount, `${attr}.name`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .account-info-modal
    width: 600px
</style>
